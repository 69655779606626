var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.root},[_c('div',{staticClass:"mb-4 clearfix"},[_c('VBtn',{attrs:{"depressed":"","icon":""},on:{"click":_vm.toHome}},[_c('VIcon',{domProps:{"textContent":_vm._s('mdi-arrow-left')}})],1),_c('InstructionButton'),(_vm.$route.meta.title)?_c('div',{class:_vm.$style.title},[_vm._v(_vm._s(_vm.$route.meta.title))]):_vm._e(),(_vm.isReviewer)?_c('VBtn',{staticClass:"ml-3 float-right",attrs:{"depressed":""},on:{"click":function($event){return _vm.$router.push({ name: 'inspect/InspectorCompanyListView' })}}},[_vm._v(" Рейтинг инспекторов ")]):_vm._e(),(_vm.isMasterInspector)?_c('VBtn',{staticClass:"ml-3 float-right",attrs:{"depressed":""},on:{"click":function($event){return _vm.$router.push({ name: 'inspect/InspectorListView', params: { master: _vm.user.id } })}}},[_vm._v(" Список инспекторов ")]):_vm._e(),(!_vm.isInspector)?[_c('VBtn',{staticClass:"ml-3 float-right",attrs:{"color":"primary","depressed":""},on:{"click":_vm.toggleMyApplication}},[(_vm.onlyMyApplication)?[_vm._v(" Все заявки ")]:[_vm._v(" Мои заявки ")]],2),_c('VBtn',{staticClass:"ml-3 float-right",attrs:{"color":"primary","depressed":""},on:{"click":_vm.downloadList}},[_vm._v(" Выгрузить в XLS ")])]:_vm._e(),(_vm.isReviewer)?_c('VBtn',{staticClass:"ml-3 float-right",attrs:{"color":"primary","depressed":""},on:{"click":_vm.toggleFilterComments}},[(_vm.haveActiveComments)?[_vm._v(" Все заявки ")]:[_vm._v(" Заявки с комментариями ")]],2):_vm._e(),(_vm.isInitiator)?_c('VBtn',{staticClass:"ml-3 float-right",attrs:{"color":"success","depressed":""},on:{"click":_vm.onCreate}},[_vm._v(" Создать заявку ")]):_vm._e(),_c('RoleSwitcher',{staticClass:"float-right"})],2),_c('ApplicationList',{attrs:{"headers":[
        { text: 'ID', value: 'id', sortable: false },
        { text: 'Дата создания', value: 'date', sortable: false },
        { text: 'Даты проведения инспекции', value: 'inspection', sortable: false },
        { text: 'Статус', value: 'status.name', alias: 'status', options: _vm.statuses, sortable: false },
        { text: 'Площадка', value: 'company', sortable: false },
        { text: 'Цех заказчика', value: 'workshop', sortable: false },
        { text: 'Автор заявки', value: 'author', sortable: false },
        { text: 'Согласующий', value: 'workshopReviewer', sortable: false },
        { text: 'Администратор заявки', value: 'reviewer', sortable: false },
        { text: 'Инспектор', value: 'inspector', alias: 'inspector', sortable: false },
        { text: 'Категория товара', value: 'products', alias: 'productCategory', options: _vm.products, sortable: false },
        { text: 'ИНН Поставщика', value: 'inn', alias: 'productSupplyInn', sortable: false },
        { text: 'Поставщик', value: 'supply', alias: 'productSupplyName', sortable: false },
        { text: 'Виды инспекций', value: 'type', alias: 'inspectionType', sortable: false },
      ],"items":_vm.list.items,"count":_vm.list.count,"page":_vm.page,"size":_vm.size,"loading":_vm.loading}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }