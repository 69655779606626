<template>
  <VBtn v-if="link" class="float-right ml-2" :href="link" target="_blank" depressed color="info lighten-1">
    Инструкция
  </VBtn>
</template>

<script>
import { mapGetters } from 'vuex';
import { get } from 'lodash-es';
import { USER_TYPES } from '@/store/inspect/enums';

export default {
  name: 'InstructionButton',
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
    }),
    link() {
      if (this.isInspector) return '/upload/inspect/Инструкция_Инспектор.docx';
      if (this.isWorkshopReviewer) return '/upload/inspect/Инструкция_Согласующий.docx';
      if (this.isReviewer) return '/upload/inspect/Инструкция_Администратор_Заявки.docx';
      if (this.isInitiator) return '/upload/inspect/Инструкция_Заявитель.docx';
      return '';
    },
    isReviewer() {
      return this.hasRole([get(USER_TYPES, 'REVIEWER.value', 'REVIEWER')], 'inspect');
    },
    isWorkshopReviewer() {
      return this.hasRole([get(USER_TYPES, 'WORKSHOP_REVIEWER.value', 'WORKSHOP_REVIEWER')], 'inspect');
    },
    isInitiator() {
      return this.hasRole([get(USER_TYPES, 'INITIATOR.value', 'INITIATOR')], 'inspect');
    },
    isInspector() {
      return this.hasRole([get(USER_TYPES, 'INSPECTOR.value', 'INSPECTOR')], 'inspect');
    },
  }
}
</script>

<style scoped>

</style>
