<template>
  <div :class="$style.root">
    <div class="mb-4 clearfix">
      <VBtn depressed @click="toHome" icon>
        <VIcon v-text="'mdi-arrow-left'" />
      </VBtn>
      <InstructionButton />
      <div v-if="$route.meta.title" :class="$style.title">{{$route.meta.title}}</div>
      <VBtn v-if="isReviewer" class="ml-3 float-right" depressed @click="$router.push({ name: 'inspect/InspectorCompanyListView' })">
        Рейтинг инспекторов
      </VBtn>
      <VBtn v-if="isMasterInspector" class="ml-3 float-right" depressed @click="$router.push({ name: 'inspect/InspectorListView', params: { master: user.id } })">
        Список инспекторов
      </VBtn>
      <template v-if="!isInspector">
        <VBtn color="primary" class="ml-3 float-right" depressed @click="toggleMyApplication">
          <template v-if="onlyMyApplication">
            Все заявки
          </template>
          <template v-else>
            Мои заявки
          </template>
        </VBtn>
        <VBtn color="primary" class="ml-3 float-right" depressed @click="downloadList">
          Выгрузить в XLS
        </VBtn>
      </template>
      <VBtn v-if="isReviewer" color="primary" class="ml-3 float-right" depressed @click="toggleFilterComments">
        <template v-if="haveActiveComments">
          Все заявки
        </template>
        <template v-else>
          Заявки с комментариями
        </template>
      </VBtn>
      <VBtn v-if="isInitiator" color="success"  class="ml-3 float-right" depressed @click="onCreate">
        Создать заявку
      </VBtn>
      <RoleSwitcher class="float-right" />
    </div>
    <ApplicationList
        :headers="[
          { text: 'ID', value: 'id', sortable: false },
          { text: 'Дата создания', value: 'date', sortable: false },
          { text: 'Даты проведения инспекции', value: 'inspection', sortable: false },
          { text: 'Статус', value: 'status.name', alias: 'status', options: statuses, sortable: false },
          { text: 'Площадка', value: 'company', sortable: false },
          { text: 'Цех заказчика', value: 'workshop', sortable: false },
          { text: 'Автор заявки', value: 'author', sortable: false },
          { text: 'Согласующий', value: 'workshopReviewer', sortable: false },
          { text: 'Администратор заявки', value: 'reviewer', sortable: false },
          { text: 'Инспектор', value: 'inspector', alias: 'inspector', sortable: false },
          { text: 'Категория товара', value: 'products', alias: 'productCategory', options: products, sortable: false },
          { text: 'ИНН Поставщика', value: 'inn', alias: 'productSupplyInn', sortable: false },
          { text: 'Поставщик', value: 'supply', alias: 'productSupplyName', sortable: false },
          { text: 'Виды инспекций', value: 'type', alias: 'inspectionType', sortable: false },
        ]"
        :items="list.items"
        :count="list.count"
        :page="page"
        :size="size"
        :loading="loading"
    />
  </div>
</template>

<script>
import {debounce, values, get, toString} from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import { APPLICATION_STATUSES as STATUSES, USER_TYPES } from '@/store/inspect/enums';
import InstructionButton from '@/components/inspect/InstructionButton/InstructionButton';
import ApplicationList from '@/components/inspect/ApplicationList/ApplicationList';
import RoleSwitcher from '@/components/inspect/RoleSwitcher/RoleSwitcher';
export default {
  name: 'ApplicationListView',
  components: {
    ApplicationList,
    RoleSwitcher,
    InstructionButton,
  },
  props: {
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    filter: { type: Object }
  },
  computed: {
    ...mapGetters({
      loading: 'inspect/pending',
      list: 'inspect/applications',
      hasRole: 'user/hasRole',
      user: 'user/current',
    }),
    products() {
      return get(this.list, 'dictionary.productCategory', []);
    },
    statuses() {
      return values(STATUSES);
    },
    isReviewer() {
      return this.hasRole(get(USER_TYPES, 'REVIEWER.value', 'REVIEWER'), 'inspect');
    },
    isInitiator() {
      return this.hasRole(get(USER_TYPES, 'INITIATOR.value', 'INITIATOR'), 'inspect');
    },
    isInspector() {
      return this.hasRole(get(USER_TYPES, 'INSPECTOR.value', 'INSPECTOR'), 'inspect');
    },
    isMasterInspector() {
      return this.isInspector && !toString(get(this.user, 'masterUser.id'));
    },
    haveActiveComments() {
      return get(this.filter, ['haveActiveComments']) === 'Y';
    },
    onlyMyApplication() {
      return get(this.filter, ['onlyMy']) === 'Y';
    }
  },
  methods: {
    ...mapActions({
      createApplication: 'inspect/createApplication',
      fetchApplicationList: 'inspect/fetchApplicationList',
      downloadApplicationList: 'inspect/downloadApplicationList',
    }),
    onCreate() {
      return this.createApplication().then((application) => {
        const id = application.id;
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Заявка успешно создана',
        });
        if (id) this.$router.push({ name: 'inspect/ApplicationDetailView', params: { application: id }});
        return application;
      });
    },
    onUpdateState: debounce(function() {
      const { page, size, filter } = this;
      return this.fetchApplicationList({ page, size, filter });
    }, 500),
    downloadList() {
      const { page, size, filter } = this;
      this.downloadApplicationList({ page, size, filter });
    },
    toHome() {
      window.location = '/';
    },
    toggleFilterComments() {
      const { query } = this.$route;
      const value = this.haveActiveComments ? 'N' : 'Y';
      this.$router.push({ name: 'inspect/ApplicationListView', query: { ...query, haveActiveComments: value } });
    },
    toggleMyApplication() {
      const { query } = this.$route;
      const value = this.onlyMyApplication ? 'N' : 'Y';
      this.$router.push({ name: 'inspect/ApplicationListView', query: { ...query, onlyMy: value } });
    }
  },
  watch: {
    page: function() {
      this.onUpdateState();
    },
    size: function() {
      this.onUpdateState();
    },
    filter: {
      deep: true,
      handler: function() {
        this.onUpdateState();
      }
    }
  },
  mounted() {
    this.onUpdateState();
  }
}
</script>

<style module lang="scss">
.root {}
.title {
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  font-weight: bold;
  font-size: 18px;
  margin-left: 10px;
  margin-right: 10px;
}
</style>
